<template>
  <div>
    <Header/> 
    <div class="contents_text_one">
      <!-- <router-link :to="{path:'/'}">
        <img class="contents_img_1_one" src="../../assets/content/logo_s.png">
      </router-link> -->
        <img class="contents_img_2_one" src="../../assets/index/add_icon.png">
        <div calss="contents_study_one">分享：{{title}}</div>
    </div>
    
    <div class="sv_1_1">
        <div v-for="father_item in father_content" :key="father_item">
            <div class="flex-align-con_s sort-list-item_s">
                <div class="newest">{{father_item.title}}</div>
                <a :href="'share_text_sort?type='+father_item.id">
                    <div class="more">更多<img src="../../assets/share_pic_sort/arrow_d_icon.png" class="highlight-image-color_s"></div>
                </a>
            </div>
            <div v-for="(item,key) in content[father_item.id]" :key="key">
                <router-link :to="{path:'/share_text_info/'+item.id}">
                    <div class="flex-align-con_s sort-list-item_s_s" style="background: #dbdbdb;color: rgb(97, 97, 97);">
                        <div class="newest" id="newests">{{item.title}}</div>
                        <a href="share_text_sort?type=newest">
                            <div class="more" style="filter: invert(29%) sepia(0%) saturate(0%) hue-rotate(38deg) brightness(98%) contrast(92%);"><img src="../../assets/share_pic_sort/arrow_d_icon.png" class="highlight-image-color_s_s"></div>
                        </a>
                    </div>
                </router-link>
            </div>
        </div>

        <div v-for="(item,key) in type_content" :key="key">
            <router-link :to="{path:'/share_text_info/'+item.id}">
                <div class="flex-align-con_s sort-list-item_s_s" style="color: rgb(97, 97, 97);">
                    <div class="newest" id="newests">{{item.title}}</div>
                    <a href="share_text_sort?type=newest">
                        <div class="more" ><img src="../../assets/share_pic_sort/arrow_d_icon.png" class="highlight-image-color_s_s"></div>
                    </a>
                </div>
            </router-link>
        </div>
    </div>

  </div>
</template>

<style>
#newests{
    width:90%;
    text-align:left;
    font-size:18px;
}
#newest{
    width:90%;
    text-align:left;
    font-size:18px;
    color:rgb(97, 97, 97);
}
.newest_div{
    width:33.3%;
    height:32vh;
    float:left;
}
.newest{
    font-size: 20px;
    margin-left: 5px;
    float:left;
}
.more{
    font-size: 18px;
    font-weight: 300;
    float:right;
    display: flex;
    padding: 1vw 1vh;
    color: #000000;
}
.highlight-image-color_s{
    filter: invert(61%) sepia(94%) saturate(186%) hue-rotate(352deg) brightness(88%) contrast(85%);
    margin-left: 5px;
    width:24px;
    height:24px;
}
.highlight-image-color_s_s{
    filter: invert(61%) sepia(94%) saturate(186%) hue-rotate(352deg) brightness(88%) contrast(85%);
    margin-left: 5px;
    width:20px;
    height:20px;
}
.sort-list-item_s{
    height:5vh;
    border-bottom: 1px solid rgba(97,97,97,.3);
    padding: 8px;
    align-items: center;
    clear: both;
}
.sort-list-item_s_s{
    display: flex;
    border-bottom: 1px solid rgba(97,97,97,.3);
    padding: 8px;
    align-items: center;
    clear: both;
}
  .sv_1_1{
    height: auto;
    width: 100%;
    background:#fff;
    min-height: 84vh;
  }
  .contents_study_one{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .contents_text_one{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .contents_img_1_one{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .contents_img_2_one{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
</style>
<script>
import Header from "../Header";
import {apiShares,apiShareTypes} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
        title:'文字内容',
        type:'',
        financialPlanning:[],
        insuranceTopic:[],
        father_content:[],
        content:[],
        type_content:[],
    }
  },
  computed:{
    //如果这里有监听到内容的话说明 是从金局海报进来的 而不是从理财
    fatherContent(){
      return this.father_content;
    }
  },
  watch:{
    fatherContent(val){
        let father_content = val;
        if(father_content.length > 0){
            for(let i=0; i<father_content.length; i++){
                apiShares({
                    lang_id:this.$store.state.lang_id,
                    share_type_id:father_content[i].id
                }).then(ress=>{
                    if(ress.data.data.length > 0){
                        // types_content
                        this.content[father_content[i].id] = ress.data.data;
                    }
                }).catch(errors=>{
                    console.log(errors);
                })
            }
        }
    }
  },
  created(){
    let type = this.$route.query.type;
    this.type = type;
    if(type && type > 0){
        apiShareTypes({
            parent_id:type,
            lang_id:this.$store.state.lang_id,
        }).then(res=>{
            // 如果这里有值说明他是最上级 也就是 金句海报，文字内容
            if(res.data.data.length > 0){
                this.father_content = res.data.data;
            }else{
                // 否则就是从正常的类型下进入的
                 apiShares({
                    share_type_id:type,
                    lang_id:this.$store.state.lang_id,
                }).then(ress=>{
                    this.type_content = ress.data.data;
                }).catch(error=>{
                    console.log(error);
                })
            }
        }).catch(error=>{
            console.log(error);
        })
    }
  }
}
</script>

